var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.title,"sub-title":"操作即时生效，谨慎操作！"}}),_c('div',{staticClass:"main-content"},[_c('a-tabs',{attrs:{"default-active-key":_vm.tabIndex,"animated":false},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:0,attrs:{"tab":"首页设置"}},[_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v("首页LOGO和背景图")])])]),_c('div',{staticClass:"item"},[(!_vm.logoList)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleLogo()}}},[_vm._v(" 新增图片 ")]):_vm._e()],1)]),_c('my-table',{attrs:{"columns":_vm.columnsLogo,"data-source":_vm.logoList,"loading":_vm.bannerLoading,"pagination":false},scopedSlots:_vm._u([{key:"icon",fn:function(record){return [_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":record.icon,"alt":"cover"}})])]}},{key:"cover",fn:function(record){return [_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":record.cover,"alt":"cover"}})])]}},{key:"status",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.status].value,"text":_vm.statuText[record.status].label}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onBannerEdit(record,'logo')}}},[_vm._v(" 编辑 ")]),_c('a-dropdown',{attrs:{"placement":"bottomCenter"}},[_c('a-icon',{attrs:{"type":"ellipsis"}}),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:0,on:{"click":function($event){return _vm.setBannerStatus({
                      type: 1,
                      id: record.id,
                      status: record.status
                    })}}},[(record.status === 0)?_c('span',[_vm._v("显示")]):_c('span',[_vm._v("隐藏")])]),_c('a-menu-item',{key:1,on:{"click":function($event){return _vm.onBannerDel({
                      type: 1,
                      id: record.id
                    })}}},[_vm._v(" 删除 ")])],1)],1)]}}])}),_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v("首屏轮播广告图（Banner）")]),_c('span',{staticClass:"title-2"},[_vm._v("建议图片尺寸：1234 x 1234 px")])])]),_c('div',{staticClass:"item"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleDrawerOpen({ type: 1 })}}},[_vm._v(" 新增广告图 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.banners,"loading":_vm.bannerLoading,"pagination":false},scopedSlots:_vm._u([{key:"cover",fn:function(record){return [_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":record.cover,"alt":"cover"}})])]}},{key:"jumptype",fn:function(record){return [_c('span',[_vm._v(_vm._s(_vm.jumpSelects[record.jumptype - 1].label))])]}},{key:"url",fn:function(record){return [(record.url)?_c('span',[_vm._v(_vm._s(record.url))]):_c('span',[_vm._v("-")])]}},{key:"status",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.status].value,"text":_vm.statuText[record.status].label}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onBannerEdit(record)}}},[_vm._v(" 编辑 ")]),_c('a-dropdown',{attrs:{"placement":"bottomCenter"}},[_c('a-icon',{attrs:{"type":"ellipsis"}}),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:0,on:{"click":function($event){return _vm.setBannerStatus({
                      type: 1,
                      id: record.id,
                      status: record.status
                    })}}},[(record.status === 0)?_c('span',[_vm._v("显示")]):_c('span',[_vm._v("隐藏")])]),_c('a-menu-item',{key:1,on:{"click":function($event){return _vm.onBannerDel({
                      type: 1,
                      id: record.id
                    })}}},[_vm._v(" 删除 ")])],1)],1)]}}])}),_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v("金刚区入口")])])]),_c('div',{staticClass:"item"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleDrawerOpen({ type: 3 })}}},[_vm._v(" 新增金刚区 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columns2,"data-source":_vm.navs,"loading":_vm.navLoading,"pagination":false},scopedSlots:_vm._u([{key:"cover",fn:function(record){return [_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":record.cover,"alt":"cover"}})])]}},{key:"jumptype",fn:function(record){return [_c('span',[_vm._v(_vm._s(_vm.jumpSelects[record.jumptype - 1].label))])]}},{key:"url",fn:function(record){return [(record.url)?_c('span',[_vm._v(_vm._s(record.url))]):_c('span',[_vm._v("-")])]}},{key:"status",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.status].value,"text":_vm.statuText[record.status].label}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onNavEdit(record)}}},[_vm._v(" 编辑 ")]),_c('a-dropdown',{attrs:{"placement":"bottomCenter"}},[_c('a-icon',{attrs:{"type":"ellipsis"}}),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:0,on:{"click":function($event){return _vm.setNavStatus({
                      id: record.id,
                      status: record.status
                    })}}},[(record.status === 0)?_c('span',[_vm._v("显示")]):_c('span',[_vm._v("隐藏")])]),_c('a-menu-item',{key:1,on:{"click":function($event){return _vm.onNavDel(record.id)}}},[_vm._v(" 删除 ")])],1)],1)]}}])}),_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v("通栏轮播广告图")]),_c('span',{staticClass:"title-2"},[_vm._v("建议图片尺寸：1234 x 1234 px")])])]),_c('div',{staticClass:"item"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleDrawerOpen({ type: 2 })}}},[_vm._v(" 新增广告图 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.bannersSub,"loading":_vm.bannerSubLoading,"pagination":false},scopedSlots:_vm._u([{key:"cover",fn:function(record){return [_c('div',{staticClass:"cover"},[_c('img',{staticClass:"img",attrs:{"src":record.cover,"alt":"cover"}})])]}},{key:"jumptype",fn:function(record){return [_c('span',[_vm._v(_vm._s(_vm.jumpSelects[record.jumptype - 1].label))])]}},{key:"url",fn:function(record){return [(record.url)?_c('span',[_vm._v(_vm._s(record.url))]):_c('span',[_vm._v("-")])]}},{key:"status",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.status].value,"text":_vm.statuText[record.status].label}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onBannerEdit(record)}}},[_vm._v(" 编辑 ")]),_c('a-dropdown',{attrs:{"placement":"bottomCenter"}},[_c('a-icon',{attrs:{"type":"ellipsis"}}),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:0,on:{"click":function($event){return _vm.setBannerStatus({
                      type: 2,
                      id: record.id,
                      status: record.status
                    })}}},[(record.status === 0)?_c('span',[_vm._v("显示")]):_c('span',[_vm._v("隐藏")])]),_c('a-menu-item',{key:1,on:{"click":function($event){return _vm.onBannerDel({
                      type: 2,
                      id: record.id
                    })}}},[_vm._v(" 删除 ")])],1)],1)]}}])})],1),_c('a-tab-pane',{key:1,attrs:{"tab":"其他设置"}},[_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v("TabBar图标管理")]),_c('span',{staticClass:"title-2"},[_vm._v("建议图片尺寸：81 x 81 px")])])]),_c('div',{staticClass:"item"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$jump({ path: '/clientUserTabbarIcon' })}}},[_vm._v(" 编辑图标 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columnsIcon,"data-source":_vm.tabbars,"loading":_vm.tabbarLoading,"pagination":false},scopedSlots:_vm._u([{key:"icon1",fn:function(record){return [(record.dicon)?_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":record.dicon,"alt":"icon"}})]):_c('span',[_vm._v("-")])]}},{key:"icon2",fn:function(record){return [(record.icon)?_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":record.icon,"alt":"icon"}})]):_c('span',[_vm._v("-")])]}}])}),_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v("缺省语句")])])])]),_c('my-table',{attrs:{"columns":_vm.columnsDefault,"data-source":_vm.defaults,"loading":_vm.defaultLoading,"pagination":false}})],1)],1)],1),_c('drawer',{attrs:{"title":_vm.drawerTitle,"width":376,"visible":_vm.visible}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[(_vm.form.type === undefined && _vm.LogoPic == '')?_c('a-form-model-item',{ref:"name",attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入名称"},on:{"blur":function () {
              _vm.$refs.name.onFieldBlur()
            }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1):_vm._e(),(_vm.LogoPic == '')?_c('a-form-model-item',{ref:"sort",attrs:{"label":"排序","prop":"sort"}},[_c('a-input',{attrs:{"placeholder":"请输入排序"},on:{"blur":function () {
              _vm.$refs.sort.onFieldBlur()
            }},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1):_vm._e(),(_vm.LogoPic == '')?_c('a-form-model-item',{attrs:{"label":"跳转类型","prop":"jumptype"}},[_c('a-select',{model:{value:(_vm.form.jumptype),callback:function ($$v) {_vm.$set(_vm.form, "jumptype", $$v)},expression:"form.jumptype"}},_vm._l((_vm.jumpSelects),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1):_vm._e(),(_vm.form.jumptype === 3)?_c('a-form-model-item',{ref:"appid",attrs:{"label":"APPID","prop":"appid"}},[_c('a-input',{attrs:{"placeholder":"请输入APPID"},model:{value:(_vm.form.appid),callback:function ($$v) {_vm.$set(_vm.form, "appid", $$v)},expression:"form.appid"}})],1):_vm._e(),(_vm.LogoPic == '')?_c('a-form-model-item',{ref:"url",attrs:{"label":"跳转指向","prop":"url"}},[_c('a-input',{attrs:{"placeholder":"请输入跳转指向"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})],1):_vm._e(),(_vm.LogoPic == 'logo')?_c('a-form-model-item',{staticClass:"banner",attrs:{"prop":"icon","label":"LOGO"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"file","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl,"before-upload":_vm.beforeUpload,"data":{
            token: _vm.qiniuData.token
          }},on:{"change":_vm.handleChangeLogo}},[(_vm.form.icon)?_c('div',{staticClass:"avatar-box"},[_c('img',{staticClass:"img",attrs:{"src":_vm.form.icon,"alt":"icon"}})]):_c('div',[_c('a-icon',{attrs:{"type":_vm.imgLoading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传LOGO图片")])],1)])],1):_vm._e(),_c('a-form-model-item',{staticClass:"banner",attrs:{"prop":"cover","label":"图片"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"file","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl,"before-upload":_vm.beforeUpload,"data":{
            token: _vm.qiniuData.token
          }},on:{"change":_vm.handleChange}},[(_vm.form.cover)?_c('div',{staticClass:"avatar-box"},[_c('img',{staticClass:"img",attrs:{"src":_vm.form.cover,"alt":"cover"}})]):_c('div',[_c('a-icon',{attrs:{"type":_vm.imgLoading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1)])],1),_c('div',{staticClass:"drawer-btn-wrap"},[_c('a-button',{on:{"click":_vm.handleDrawerClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDrawerSubmit}},[_vm._v(" 提交 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }