<template>
  <!-- 用户端管理 -->
  <div class="container">
    <a-page-header :title="title" sub-title="操作即时生效，谨慎操作！" />

    <div class="main-content">
      <a-tabs
        :default-active-key="tabIndex"
        :animated="false"
        @change="callback"
      >
        <a-tab-pane :key="0" tab="首页设置">
          <!-- 首页logo背景颜色 -->
          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span class="title-1">首页LOGO和背景图</span>
                <!-- <span class="title-2">建议图片尺寸：1234 x 1234 px</span> -->
              </div>
            </div>
            <div class="item">
              <a-button type="primary" @click="handleLogo()" v-if="!logoList">
                新增图片
              </a-button>
            </div>
          </div>
          <my-table
            :columns="columnsLogo"
            :data-source="logoList"
            :loading="bannerLoading"
            :pagination="false"
          >
            <template slot="icon" slot-scope="record">
              <div class="icon">
                <img class="img" :src="record.icon" alt="cover" />
              </div>
            </template>
            <template slot="cover" slot-scope="record">
              <div class="icon">
                <img class="img" :src="record.cover" alt="cover" />
              </div>
            </template>
            <!-- <template slot="jumptype" slot-scope="record">
              <span>{{ jumpSelects[record.jumptype - 1].label }}</span>
            </template>
            <template slot="url" slot-scope="record">
              <span v-if="record.url">{{ record.url }}</span>
              <span v-else>-</span>
            </template> -->
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].value"
                :text="statuText[record.status].label"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" @click="onBannerEdit(record,'logo')">
                编辑
              </a-button>
              <a-dropdown placement="bottomCenter">
                <a-icon type="ellipsis" />
                <a-menu slot="overlay">
                  <a-menu-item
                    :key="0"
                    @click="
                      setBannerStatus({
                        type: 1,
                        id: record.id,
                        status: record.status
                      })
                    "
                  >
                    <span v-if="record.status === 0">显示</span>
                    <span v-else>隐藏</span>
                  </a-menu-item>
                  <a-menu-item
                    :key="1"
                    @click="
                      onBannerDel({
                        type: 1,
                        id: record.id
                      })
                    "
                  >
                    删除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </my-table>
          <!-- 首屏轮播广告图-start -->
          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span class="title-1">首屏轮播广告图（Banner）</span>
                <span class="title-2">建议图片尺寸：1234 x 1234 px</span>
              </div>
            </div>
            <div class="item">
              <a-button type="primary" @click="handleDrawerOpen({ type: 1 })">
                新增广告图
              </a-button>
            </div>
          </div>
          <my-table
            :columns="columns"
            :data-source="banners"
            :loading="bannerLoading"
            :pagination="false"
          >
            <template slot="cover" slot-scope="record">
              <div class="icon">
                <img class="img" :src="record.cover" alt="cover" />
              </div>
            </template>
            <template slot="jumptype" slot-scope="record">
              <span>{{ jumpSelects[record.jumptype - 1].label }}</span>
            </template>
            <template slot="url" slot-scope="record">
              <span v-if="record.url">{{ record.url }}</span>
              <span v-else>-</span>
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].value"
                :text="statuText[record.status].label"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" @click="onBannerEdit(record)">
                编辑
              </a-button>
              <a-dropdown placement="bottomCenter">
                <a-icon type="ellipsis" />
                <a-menu slot="overlay">
                  <a-menu-item
                    :key="0"
                    @click="
                      setBannerStatus({
                        type: 1,
                        id: record.id,
                        status: record.status
                      })
                    "
                  >
                    <span v-if="record.status === 0">显示</span>
                    <span v-else>隐藏</span>
                  </a-menu-item>
                  <a-menu-item
                    :key="1"
                    @click="
                      onBannerDel({
                        type: 1,
                        id: record.id
                      })
                    "
                  >
                    删除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </my-table>
          <!-- 首屏轮播广告图-end -->

          <!-- 金刚区-start -->
          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span class="title-1">金刚区入口</span>
              </div>
            </div>
            <div class="item">
              <a-button type="primary" @click="handleDrawerOpen({ type: 3 })">
                新增金刚区
              </a-button>
            </div>
          </div>
          <my-table
            :columns="columns2"
            :data-source="navs"
            :loading="navLoading"
            :pagination="false"
          >
            <template slot="cover" slot-scope="record">
              <div class="icon">
                <img class="img" :src="record.cover" alt="cover" />
              </div>
            </template>
            <template slot="jumptype" slot-scope="record">
              <span>{{ jumpSelects[record.jumptype - 1].label }}</span>
            </template>
            <template slot="url" slot-scope="record">
              <span v-if="record.url">{{ record.url }}</span>
              <span v-else>-</span>
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].value"
                :text="statuText[record.status].label"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" @click="onNavEdit(record)"> 编辑 </a-button>
              <a-dropdown placement="bottomCenter">
                <a-icon type="ellipsis" />
                <a-menu slot="overlay">
                  <a-menu-item
                    :key="0"
                    @click="
                      setNavStatus({
                        id: record.id,
                        status: record.status
                      })
                    "
                  >
                    <span v-if="record.status === 0">显示</span>
                    <span v-else>隐藏</span>
                  </a-menu-item>
                  <a-menu-item :key="1" @click="onNavDel(record.id)">
                    删除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </my-table>
          <!-- 金刚区-end -->

          <!-- 通栏轮播广告图-start -->
          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span class="title-1">通栏轮播广告图</span>
                <span class="title-2">建议图片尺寸：1234 x 1234 px</span>
              </div>
            </div>
            <div class="item">
              <a-button type="primary" @click="handleDrawerOpen({ type: 2 })">
                新增广告图
              </a-button>
            </div>
          </div>
          <my-table
            :columns="columns"
            :data-source="bannersSub"
            :loading="bannerSubLoading"
            :pagination="false"
          >
            <template slot="cover" slot-scope="record">
              <div class="cover">
                <img class="img" :src="record.cover" alt="cover" />
              </div>
            </template>
            <template slot="jumptype" slot-scope="record">
              <span>{{ jumpSelects[record.jumptype - 1].label }}</span>
            </template>
            <template slot="url" slot-scope="record">
              <span v-if="record.url">{{ record.url }}</span>
              <span v-else>-</span>
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].value"
                :text="statuText[record.status].label"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" @click="onBannerEdit(record)">
                编辑
              </a-button>
              <a-dropdown placement="bottomCenter">
                <a-icon type="ellipsis" />
                <a-menu slot="overlay">
                  <a-menu-item
                    :key="0"
                    @click="
                      setBannerStatus({
                        type: 2,
                        id: record.id,
                        status: record.status
                      })
                    "
                  >
                    <span v-if="record.status === 0">显示</span>
                    <span v-else>隐藏</span>
                  </a-menu-item>
                  <a-menu-item
                    :key="1"
                    @click="
                      onBannerDel({
                        type: 2,
                        id: record.id
                      })
                    "
                  >
                    删除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </my-table>
          <!-- 通栏轮播广告图-end -->
        </a-tab-pane>

        <a-tab-pane :key="1" tab="其他设置">
          <!-- TabBar图标管理-start -->
          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span class="title-1">TabBar图标管理</span>
                <span class="title-2">建议图片尺寸：81 x 81 px</span>
              </div>
            </div>
            <div class="item">
              <a-button
                type="primary"
                @click="$jump({ path: '/clientUserTabbarIcon' })"
              >
                编辑图标
              </a-button>
            </div>
          </div>
          <my-table
            :columns="columnsIcon"
            :data-source="tabbars"
            :loading="tabbarLoading"
            :pagination="false"
          >
            <template slot="icon1" slot-scope="record">
              <div class="icon" v-if="record.dicon">
                <img class="img" :src="record.dicon" alt="icon" />
              </div>
              <span v-else>-</span>
            </template>
            <template slot="icon2" slot-scope="record">
              <div class="icon" v-if="record.icon">
                <img class="img" :src="record.icon" alt="icon" />
              </div>
              <span v-else>-</span>
            </template>
          </my-table>
          <!-- TabBar图标管理-end -->

          <!-- 缺省语句-start -->
          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span class="title-1">缺省语句</span>
              </div>
            </div>
          </div>
          <my-table
            :columns="columnsDefault"
            :data-source="defaults"
            :loading="defaultLoading"
            :pagination="false"
          >
          </my-table>
          <!-- 缺省语句-end -->
        </a-tab-pane>
      </a-tabs>
    </div>

    <drawer :title="drawerTitle" :width="376" :visible="visible">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item
          ref="name"
          label="名称"
          prop="name"
          v-if="form.type === undefined && LogoPic == ''"
        >
          <a-input
            placeholder="请输入名称"
            v-model="form.name"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="sort" label="排序" prop="sort"  v-if="LogoPic == ''">
          <a-input
            placeholder="请输入排序"
            v-model="form.sort"
            @blur="
              () => {
                $refs.sort.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          label="跳转类型"
          prop="jumptype"
          v-if="LogoPic == ''"
        >
          <a-select v-model="form.jumptype">
            <a-select-option
              v-for="item in jumpSelects"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          ref="appid"
          label="APPID"
          prop="appid"
          v-if="form.jumptype === 3"
        >
          <a-input v-model="form.appid" placeholder="请输入APPID" />
        </a-form-model-item>
        <a-form-model-item
          ref="url"
          label="跳转指向"
          prop="url"
          v-if="LogoPic == ''"
        >
          <a-input v-model="form.url" placeholder="请输入跳转指向" />
        </a-form-model-item>
        <!-- 首页上传图片 -->
        <a-form-model-item
          class="banner"
          prop="icon"
          label="LOGO"
          v-if="LogoPic == 'logo'"
        >
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            :data="{
              token: qiniuData.token
            }"
            @change="handleChangeLogo"
          >
            <div class="avatar-box" v-if="form.icon">
              <img class="img" :src="form.icon" alt="icon" />
            </div>
            <div v-else>
              <a-icon :type="imgLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传LOGO图片</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <!-- 上传图片 -->
        <a-form-model-item class="banner" prop="cover" label="图片">
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            :data="{
              token: qiniuData.token
            }"
            @change="handleChange"
          >
            <div class="avatar-box" v-if="form.cover">
              <img class="img" :src="form.cover" alt="cover" />
            </div>
            <div v-else>
              <a-icon :type="imgLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <!-- 上传图片 -->
        <div class="drawer-btn-wrap">
          <a-button @click="handleDrawerClose"> 取消 </a-button>
          <a-button type="primary" @click="handleDrawerSubmit"> 提交 </a-button>
        </div>
      </a-form-model>
    </drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import drawer from '../../components/drawer/index'
export default {
  data() {
    return {
      title: '',
      tabIndex: 0,
      banners: [],
      logoList: [],
      bannersSub: [],
      tabbars: [],
      defaults: [],
      columnsLogo: [
        // banner
        {
          title: '排序',
          dataIndex: 'id',
          width: 60
        },
        {
          title: 'LOGO',
          scopedSlots: { customRender: 'icon' },
          width: 140
        },
        {
          title: '背景图',
          scopedSlots: { customRender: 'cover' },
          width: 100
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columns: [
        // banner
        {
          title: '排序',
          dataIndex: 'sort',
          width: 60
        },
        {
          title: '缩略图',
          scopedSlots: { customRender: 'cover' },
          width: 100
        },
        {
          title: '跳转类型',
          scopedSlots: { customRender: 'jumptype' },
          width: 140
        },
        {
          title: '跳转指向（网址/路径）',
          scopedSlots: { customRender: 'url' },
          width: 200
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columnsIcon: [
        // 图标
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '图标名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '未选中图标',
          width: 200,
          scopedSlots: { customRender: 'icon1' }
        },
        {
          title: '选中图标',
          scopedSlots: { customRender: 'icon2' }
        }
      ],
      columnsDefault: [
        // 缺省
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '修改示例图',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '文案内容',
          dataIndex: 'content'
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columns2: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '名称',
          dataIndex: 'name',
          width: 150
        },
        {
          title: 'Icon（图标）',
          width: 150,
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '跳转类型',
          width: 150,
          scopedSlots: { customRender: 'jumptype' }
        },
        {
          title: '跳转指向（网址/路径）',
          width: 200,
          scopedSlots: { customRender: 'url' }
        },
        {
          title: '显示状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      navs: [],
      navLoading: true,
      bannerLoading: true,
      bannerSubLoading: true,
      tabbarLoading: true,
      defaultLoading: true,
      statuText: {
        1: {
          value: 'success',
          label: '显示'
        },
        0: {
          value: 'default',
          label: '隐藏'
        }
      },
      jumpSelects: [
        {
          value: 1,
          label: '小程序内页'
        },
        {
          value: 2,
          label: 'H5页面'
        },
        {
          value: 3,
          label: '跳转第三方小程序'
        }
      ],
      visible: false,
      imgLoading: false,
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      drawerTitle: '',

      form: {
        type: undefined,
        sort: '',
        jumptype: 1,
        url: '',
        cover: '',
        name: '',
        icon: ''
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        jumptype: [
          { required: true, message: '请选择跳转类型', trigger: 'change' }
        ],
        appid: [{ required: true, message: '请输入APPID', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传图片', trigger: 'change' }],
        icon: [{ required: true, message: '请上传图片', trigger: 'change' }]
      },
      LogoPic: ''
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  components: {
    drawer
  },
  created() {
    this.title = this.$getPageTitle()
    this.getClientUserBanner()
    this.getClientUserNav()
    this.getClientUserBannerSub()
    this.getMemberPageFist()
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (key && !this.tabbars.length) {
        this.getClientUserTabBarIcon()
      }
    },
    getMemberPageFist() {
      // 获取首页LOGO和背景图
      const data = {
        areaType: 1
      }
      this.$axios.getMemberPageFist(data).then((res) => {
        this.logoList = res.data.data
        this.bannerLoading = false
        console.log('logoList', this.logoList)
      })
    },
    getClientUserBanner() {
      // 获取首屏轮播广告图
      const data = {
        type: 1
      }
      this.$axios.getClientUserBanner(data).then((res) => {
        this.banners = res.data.data.list
        this.bannerLoading = false
      })
    },
    getClientUserNav() {
      // 获取首屏金刚区按钮
      const data = {
        type: 1
      }
      this.$axios.getClientUserNav(data).then((res) => {
        this.navs = res.data.data.list
        this.navLoading = false
      })
    },
    getClientUserBannerSub() {
      // 获取通栏轮播广告图
      const data = {
        type: 2
      }
      this.$axios.getClientUserBanner(data).then((res) => {
        this.bannersSub = res.data.data.list
        this.bannerSubLoading = false
      })
    },
    handleChangeLogo(info) {
      // LOGO图片上传
      if (info.file.status === 'uploading') {
        if (this.form.icon) {
          this.form.icon = ''
        }
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.icon = this.qiniuData.host + info.file.response.key
        this.imgLoading = false
      }
    },
    handleChange(info) {
      // 图片上传
      if (info.file.status === 'uploading') {
        if (this.form.cover) {
          this.form.cover = ''
        }
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.cover = this.qiniuData.host + info.file.response.key
        this.imgLoading = false
      }
    },
    async beforeUpload() {
      // 图片上传检验
      // const examine = {
      //   width: 1000,
      //   height: 1000
      // }
      // await this.$examineImgWH(file, examine)
      return true
    },
    handleDrawerOpen(e) {
      // 新建
      if (e.type < 3) {
        this.form.type = e.type
      }
      this.drawerTitle = '新建'
      this.visible = true
    },
    handleLogo() {
      this.LogoPic = 'logo'
      this.drawerTitle = '新建'
      this.visible = true
    },
    handleDrawerClose() {
      // 关闭表单抽屉
      this.visible = false
      this.LogoPic = ''
      if (this.form.id) {
        delete this.form.id
      }
      this.form.type = undefined
      this.$refs.ruleForm.resetFields()
    },
    handleDrawerSubmit() {
      // 提交banner
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.id) {
            if (this.form.type === undefined && this.LogoPic == '') {
              this.editClientUserNav()
              console.log('编辑金刚')
            } else {
               if (this.LogoPic) {
                 this.eidtMemberPageFist()
                //  console.log("这里")
               }else{
                 this.editClientUserBanner()
               }
              
            }
          } else {
            if (this.form.type === undefined && this.LogoPic == '') {
              this.addClientUserNav()
              console.log('新增金刚')
            } else {
              if (!this.LogoPic) {
                this.addClientUserBanner()
              } else {
                console.log('222222')
                this.addMemberPageFist()
              }
            }
          }
        }
      })
    },
    addClientUserBanner() {
      // 添加banner

      const data = JSON.parse(JSON.stringify(this.form))
      delete data.name
      this.$axios.addClientUserBanner(data).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        if (data.type === 1) {
          this.getClientUserBanner()
        } else {
          this.getClientUserBannerSub()
        }
      })
    },
    // 新增LOGO和背景图
    addMemberPageFist() {
      // 添加banner
      const data = JSON.parse(JSON.stringify(this.form))
      data.areaType = 1
      delete data.name
      this.$axios.addMemberPageFist(data).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        this.getMemberPageFist()
        // if (data.type === 1) {
        //   this.getClientUserBanner()
        // } else {
        //   this.getClientUserBannerSub()
        // }
      })
    },
    eidtMemberPageFist(){
      // 编辑LOGO和首页
       const data = JSON.parse(JSON.stringify(this.form))
      data.areaType = 1
      this.$axios.eidtMemberPageFist(data).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        this.getMemberPageFist()
        // if (this.form.type === 1) {
        //   this.getClientUserBanner()
        // } else {
        //   this.getClientUserBannerSub()
        // }
      })
    },
    editClientUserBanner() {
      // 编辑banner
      this.$axios.editClientUserBanner(this.form).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        if (this.form.type === 1) {
          this.getClientUserBanner()
        } else {
          this.getClientUserBannerSub()
        }
      })
    },
    setBannerStatus(e) {
      // 设置banner显示隐藏状态
      let title = ''
      if (e.status === 1) {
        title = '确定隐藏？'
        e.status = 0
      } else {
        title = '确定显示？'
        e.status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            type: e.type, // 1:首屏轮播广告图 2：通栏轮广告图
            id: e.id,
            status: e.status // 状态 1:显示，  0:隐藏
          }
          this.$axios.setClientUserBannerStatus(data).then(() => {
            this.$message.success('操作成功')
            if (e.type === 1) {
              this.getClientUserBanner()
            } else {
              this.getClientUserBannerSub()
            }
          })
        }
      })
    },
    onBannerEdit(e,title) {
      // console.log("e",e,title)
      // banner编辑
      this.LogoPic = title
      this.form = JSON.parse(JSON.stringify(e))
      delete this.form.createAt
      delete this.form.status
      this.visible = true
    },
    onBannerDel(e) {
      this.$confirm({
        title: '确定删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: e.id,
            type: e.type
          }
          this.$axios.delClientUserBanner(data).then(() => {
            this.$message.success('操作成功')
            if (e.type === 1) {
              this.getClientUserBanner()
            } else {
              this.getClientUserBannerSub()
            }
          })
        }
      })
    },
    getClientUserTabBarIcon() {
      // 获取用户端tabBar图标
      this.$axios.getClientUserTabBarIcon().then((res) => {
        this.tabbars = res.data.data.list
        this.tabbarLoading = false
      })
    },
    addClientUserNav() {
      // 添加金刚区nav
      this.$axios.addClientUserNav(this.form).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        this.getClientUserNav()
      })
    },
    editClientUserNav() {
      // 编辑金刚区nav
      this.$axios.editClientUserNav(this.form).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        this.getClientUserNav()
      })
    },
    onNavEdit(e) {
      // 金刚区nav编辑
      this.form = JSON.parse(JSON.stringify(e))
      delete this.form.createAt
      delete this.form.status
      this.visible = true
    },
    setNavStatus(e) {
      // 设置金刚区nav显示隐藏状态
      let title = ''
      if (e.status === 1) {
        title = '确定隐藏？'
        e.status = 0
      } else {
        title = '确定显示？'
        e.status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: e.id,
            status: e.status // 状态 1:显示，  0:隐藏
          }
          this.$axios.setClientUserNavStatus(data).then(() => {
            this.$message.success('操作成功')
            this.getClientUserNav()
          })
        }
      })
    },
    onNavDel(e) {
      // 金刚区nav删除
      this.$confirm({
        title: '确定删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: e
          }
          this.$axios.delClientUserNav(data).then(() => {
            this.$message.success('操作成功')
            this.getClientUserNav()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-tabs {
  height: 100%;
}

.ant-tabs >>> .ant-tabs-content {
  background-color: #fff;
}

.icon {
  width: 40px;
}
</style>
